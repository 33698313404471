import React, { FunctionComponent, ReactNode,useEffect, useState,useMemo } from "react";
import { Button, Divider, Icon } from "@blueprintjs/core";
import Tooltip from "@mui/material/Tooltip"
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { UserNotesType ,TeamPlanResourcesTabEnum,StrategicPlanPageTabEnum} from "../../../types";
import { showNotesDialog } from "../../../store/notes/actions";
import { showFeedbackDialog } from "../../../store/UIState/actions";
import TourThisWorkspaceButton from "../../tour/TourThisWorkspaceButton";
import { getIsSetupCompleted } from "../../../store/auth/selectors";
import TeamHomeTabButtons from "../members/tabs/team-home-tab/TeamHomeTabButtons";
import InfoBar from "../resources/InfoBar";
import { useParams } from "react-router";


type OwnProps = {
  title: string;
  onInfoIconClick?: Function;
  userNotesType?: UserNotesType;
  nextElement?: ReactNode;
  isDistrictSchoolTeamHomeTab?: boolean | undefined;
};

type Props = OwnProps;

const WorkspaceHeader: FunctionComponent<Props> = (props) => {
  const {
    title,
    onInfoIconClick,
    nextElement,
    userNotesType,
    isDistrictSchoolTeamHomeTab,
  } = props;

  const isSetupCompleted = useSelector(getIsSetupCompleted);

  const intl = useIntl();
  const dispatch = useDispatch();

  const { tabId } = useParams<{ tabId?: string }>();

  const handleNotesDialogOpen = () => {
    dispatch(showNotesDialog({ type: userNotesType }));
  };

  const handleShareFeedbackClick = () => {
    dispatch(showFeedbackDialog());
  };

  return (
    <>
    <div className="breadcrumbs"><ul><li> <a href="#">{intl.formatMessage({id:"app.titles.dashboard"})}</a></li><li><span>/</span></li><li>{title}</li></ul></div>
    <div className="toolbar">
      <h1>{title}</h1>
      { ((tabId != TeamPlanResourcesTabEnum.Resources)&& (tabId != TeamPlanResourcesTabEnum.TeamDevelopment) && !!tabId) &&
             <ul style={{marginLeft:"auto"}}>
       <Tooltip
            title={intl.formatMessage({id:"app.resources.message.info-icon"})}
            placement="bottom"
            arrow
            >
          <li>
            <InfoBar />
            </li> 
            </Tooltip>
            </ul>
            }
      {nextElement}
      {onInfoIconClick && (
        <Icon
          icon="help"
          intent="none"
          className="text-gray-600 cursor-pointer"
          iconSize={24}
          onClick={() => {
            onInfoIconClick();
          } } />
      )}

      {/* <div className="flex" data-tour={"workspace-header-tour"}>
      <div className="d-flex flex-row">
        {isDistrictSchoolTeamHomeTab && (
          <div className="mt-5">
            {" "}
            <TeamHomeTabButtons />
          </div>
        )}
      </div>
    </div> */}
      {userNotesType !== 'sna_explore_strengths_needs'&& <ul>
        <div data-tour={"workspace-header-tour"}>
          {isDistrictSchoolTeamHomeTab && (
            <TeamHomeTabButtons />
          )}

        </div>
      </ul>
      }
    </div></>
  );
};

export default WorkspaceHeader;
