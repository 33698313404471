import React, {
  FunctionComponent,
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  memo,
} from "react";
// import EnterUrlDialouge from './EnterUrlDialouge';
// import ScinoteSaveDialoge from './SaveScinotePopup';
// import CancelScinotePopup from './CancelScinotePopup';
import { Icon } from "@blueprintjs/core";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormGroup } from "@blueprintjs/core";
//import Select from "react-select";
import { useIntl } from "react-intl";
// import {
//   AttachedResource,
//   AttachedResourceType,
//   AttachedToType,
//   Activity,
//   SelectorOptionType,
//   UserNotes,
//   UserNotesType,
//   userNotesTypeValues,
//   UserNotesViewableBy,
//   Meeting,
// } from "../../../../../../../../src/types";
// import { AppToaster } from "../../../../../../../../src/helpers/toaster";
// import { smallSelectorStyles } from "../../../../../../../../src/components/common/selectors";
import _, { over } from "lodash";
import { useDispatch, useSelector } from "react-redux";
// import {
//   addNotes,
//   hideNotesDialog,
//   updateDialogNote,
//   updateNotes,
// } from "../../../../../../../../src/store/notes/actions";
import { ValueType } from "react-select/src/types";
// import { AddNotesRequest } from "../../../../../../../../src/api/notes/types";
// import { isDistrictPlan,getActivePlanId } from "../../../../../../../../src/store/plans/selectors";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
// import AddResourcesButtons from "../../../../../../../../src/components/team-plan/resources/AddResourcesButtons";
// import ResourcesFormGroup from "../../../../../../../../src/components/team-plan/notes/notes-dialog/ResourcesFormGroup";
// import {
//   addResource,
//   getResources,
//   hideUpsertResourceDialog,
//   showUpsertResourceDialog,
// } from "../../../../../../../../src/store/resources/actions";
// import ResourcesTab from "../../../../../../team-plan/strategies/upsert-strategy-dialog/tabs/ResourcesTabNote";
// import ResourcesListNote from './ResourceListNote';
import { generatePath, useHistory, useLocation } from "react-router";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
  Meeting,
  SelectorOptionType,
  UserNotes,
  UserNotesType,
  UserNotesViewableBy,
  userNotesTypeValues,
} from "../../../../types";
import {
  getActivePlanId,
  isDistrictPlan,
} from "../../../../store/plans/selectors";
import { getPlans } from "../../../../store/plans/actions";
import { AddNotesRequest } from "../../../../api/notes/types";
import {
  addNotes,
  setNavigationWarning,
  updateDialogNote,
  updateNotes,
} from "../../../../store/notes/actions";
import {
  deleteResource,
  hideUpsertResourceDialog,
  showUpsertResourceDialog,
  updateResource,
} from "../../../../store/resources/actions";
import { AuthRoutes } from "../../../../App";
import ResourcesListNote from "../../../pages/strategic-plan/strategies/edit-core-components/sci-writing/CreateNotes/ResourceListNote";
import ScinoteSaveDialoge from "../../../pages/strategic-plan/strategies/edit-core-components/sci-writing/CreateNotes/SaveScinotePopup";
import ResourcesTab from "../../strategies/upsert-strategy-dialog/tabs/ResourcesTabNote";
// import { AuthRoutes } from "../../../../../../../../src/App";
// import { getPlans } from '../../../../../../../store/plans/actions';

import "./CreateEditNote.scss";
import events from "./events";
import { triggerPusherEvent } from "../../../../api/notes";
// import './NotesPages.scss'

import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../../api/config";
import Pusher from "pusher-js";
import { userNotesTypeValuePublishedNotes } from "../../../../types";
import NoInternetConnection from "../../../../NoInternetConnection";
import useIdleTimer from "../../../../helpers/hooks/useIdleTimer";
import { AppToaster } from "../../../../helpers/toaster";
import PageEditor from "../../../survey-deployment/forms/PageEditor";
import JoditEditor from "jodit-react";


type OwnProps = {
  // isPlanResources?: boolean;
  // isVertical?: boolean;
  // onClick: (
  //   resource: Partial<AttachedResource> & Pick<AttachedResource, "type">
  // ) => void;
  // attachedToType: AttachedToType;
  // hideUrl?: boolean;
  setIsSideMenuClosed?: any;
  isSideMenuClosed?: boolean;
  meetingData?: Partial<Meeting & { meetings: any[] }>;
};
console.log("hello")
type Props = OwnProps;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    maxHeight: 200,
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "lightgrey",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkgrey",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#565656",
    },
  },
  paper: {
    marginTop: 10,
    maxHeight: 200,
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "lightgrey",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkgrey",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#565656",
    },
  },
}));
interface NoteData {
  text: string;
  data?: {
    id: number;
    is_calender_note?: boolean;
    isCalenderNote?: boolean;
    resources: any[]; // adjust the type accordingly
    created_at: string;
    updated_at: string;
    note_type: string;
    note_title: string;
    short_title?: string;
    title?: string;
    is_draft: boolean;
    viewable_by: string;
    is_json: boolean;
    user_notes?: UserNotes[];
    text: string;
    text_activity: any; // adjust the type accordingly
    user: {
      id: number;
      first_name: string;
      last_name: string;
      is_active: boolean;
    };
    plan: number;
    activity: {
      name: string;
    } | null;
  };
}

const Noteseditor: FunctionComponent<Props> = (props) => {
  const selectRef = useRef<any>();
  const {
    setIsSideMenuClosed,
    isSideMenuClosed,
    //   isPlanResources,
    //   isVertical,
    //   onClick,
    //   attachedToType,
    //   hideUrl,
    meetingData,
  } = props;

  const forUserNotes: boolean = true;
  const location = useLocation();

  const isReadonly: boolean = false;
  const intl = useIntl();
  const submitButtonRef = useRef(null);

  const activePlanId = useSelector(getActivePlanId);
  // const user= useSelector(s=>s.);
  const dispatch = useDispatch();

  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const authenticatedUser = useSelector((s) => s.auth?.authenticatedUser);
  const uploadFileBtnRef = useRef<HTMLInputElement>(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [formData, setFormData] = useState({ noteTitle: "" });
  const [errors, setErrors] = useState({ noteTitle: "" });
  const [isEditorEnabled, setEditorEnabled] = useState(false);
  const [overview, setOverview] = useState<string>("");
  const [isTableConverted, setIsTableConverted] = useState<boolean>(false);
  const [isOverviewError, setIsOverviewError] = useState<boolean>(false);
  const [resources1, setResources] = useState<AttachedResource[]>([]);
  const classes = useStyles();
  const history = useHistory();
  const [selectedNote, setSelectedNote] = useState<NoteData | null>(null);
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null);
  const [isCalendarNote, setIsCalendarNote] = useState<boolean | null>(false);
  const [deleteResourcesArray,setDeleteResourcesArray] = useState<AttachedResource[]>([])
  const [isNavigationWarningEnabled, setIsNavigationWarningEnabled] = useState<
    boolean | null
  >(false);
  const [meetingId, setMeetingId] = useState<number | null>(null);
  const [warning, setWarning] = useState<string | undefined>();

  const selectedValueRef = useRef(selectedValue);

  const noteRefs = {
    selectedNote: useRef(selectedNote),
    formData: useRef(formData),
    meetingId: useRef(meetingId),
    activePlanId: useRef(activePlanId),
    overview: useRef(overview),
    resources1: useRef(resources1),
    isCalendarNote: useRef(isCalendarNote),
    deleteResourcesArray: useRef(deleteResourcesArray),
    authenticatedUser: useRef(authenticatedUser),
    selectedNoteId: useRef(selectedNoteId)
  };

  type NoteRefKeys = keyof typeof noteRefs;

  useEffect(() => {
    selectedValueRef.current = selectedValue;

    (Object.keys(noteRefs) as NoteRefKeys[]).forEach((key) => {
      noteRefs[key].current = eval(key);
    });
  }, [
    selectedValue,
    selectedNote,
    formData,
    meetingId,
    activePlanId,
    overview,
    resources1,
    isCalendarNote,
    deleteResourcesArray,
    authenticatedUser,
    selectedNoteId,
  ]);

  const modules = {
    toolbar: [
      [{ font: [] }, { header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const NOTES_GENERAL_CHANNEL = useMemo(() => {
    return "private-notes-list-" + activePlanId;
  }, [activePlanId]);

  const [isIdle, setIsIdle] = useState(false);
  const timeoutRef: any = useRef(null);
  const cancelBtnRef: any = useRef(null);


  const editorRef = useRef<any>(null);
  const JoditButtons = useMemo(
    () => [
      "source",
      "|",
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "table",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
    ],
    []
  );
const editorConfig: any = useMemo(() => ({
    toolbar: [
      ['bold', 'italic', 'underline', 'strikethrough'],
      ['orderedList', 'unorderedList'],
      ['indent', 'outdent'],
      ['link'],
      ['paragraph', 'h1', 'h2', 'h3', 'h4'],
      ['undo', 'redo'],
      ['table'],
      ['fullsize', 'source']
    ],
    plugins: ['table'], 
    height: 400,
    placeholder: intl.formatMessage({
      id: isEditorEnabled ? "app.calendar-note-sci-start-writing" : "app.note-sci-start-writing",
    }),
    removeButtons: ['file', 'video', 'about', 'image'],
    uploader: {
      insertImageAsBase64URI: true
    },
    readonly: !isEditorEnabled,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    buttons: JoditButtons,
    buttonsMD: JoditButtons,
    buttonsSM: JoditButtons,
    buttonsXS: JoditButtons,
  }), [isEditorEnabled])

  useEffect(() => {
    dispatch(getPlans.request());
  }, []);
  useEffect(() => {
    // Access the data from the location.state
    const receivedData = (location.state as NoteData | undefined)?.data;
    setIsCalendarNote(
      receivedData?.isCalenderNote || receivedData?.is_calender_note
        ? true
        : false
    );
    if (receivedData) {
      const existingNote =
        receivedData && receivedData?.user_notes
          ? receivedData?.user_notes[0]
          : null;

      // if (!receivedData?.isCalenderNote) {
      const {
        id,
        resources,
        isCalenderNote,
        created_at,
        updated_at,
        note_type,
        note_title,
        title,
        is_draft,
        viewable_by,
        is_json,
        text,
        text_activity,
        user,
        plan,
        activity,
      } = receivedData;
      // Now you can use these individual values as needed
      setSelectedValue(note_type);
      handleNotesTypeChange(note_type);
      setOverview(
        existingNote && existingNote.text?.trim()
          ? existingNote?.text
          : existingNote?.text_activity ?? text?.trim()
          ? text
          : text_activity
      );
      if (existingNote) setSelectedNote(existingNote as any);
      else setSelectedNote(receivedData);

      if (
        receivedData?.isCalenderNote &&
        receivedData?.user_notes &&
        receivedData?.user_notes[0]?.id
      )
        setSelectedNoteId(receivedData?.user_notes[0]?.id);
      else if (!receivedData?.isCalenderNote && receivedData?.id)
        setSelectedNoteId(receivedData.id);

      if (receivedData?.isCalenderNote) setMeetingId(receivedData.id);
      setFormData({
        ...formData,
        noteTitle: receivedData?.short_title ?? note_title,
      });
      if (existingNote) setResources(existingNote?.resources);
      else setResources(Array.isArray(resources) ? [...resources] : []);
      // }
    } // console.log('ulHtmlString:',ulHtmlString);
  }, [location.state]);

  const [attachedResources, setAttachedResources] = useState<
    Array<Pick<AttachedResource, "title" | "attachment" | "type">>
  >([]);
  const handleFileUploadClick = () => {
    uploadFileBtnRef.current?.click();
  };

  const options: SelectorOptionType<UserNotesType>[] = useMemo(() => {
    return (isDistrictActivePlan
      ? userNotesTypeValuePublishedNotes.districtLevel
      : userNotesTypeValuePublishedNotes.schoolLevel
    ).map((value: any) => ({
      label: intl.formatMessage(
        {
          id: `app.notes.types.PublishedNotes`,
        },
        { noteType: value }
      ),
      value: value as UserNotesType,
    }));
  }, []);

  const note: AddNotesRequest = useSelector(
    (s) =>
      s.notes.dialogs.notesDialog?.note ?? {
        text: selectedNote?.text ?? "",
        plan_id: activePlanId!,
        resources: [],
        is_json: false,
        viewable_by: UserNotesViewableBy.AllMembers,
        is_calender_note: isCalendarNote ?? false, //true,
      }
  );

  const updateResources = (resources: AttachedResource[]) => {
    dispatch(updateDialogNote({ ...note, resources: resources }));
  };
  const handleResourceEdit = (resource: AttachedResource) => () => {
    handleUpsertResourceDialogShow(resource);
  };
  const handleUpsertResourceDialogShow = (
    resource: Partial<AttachedResource> & Pick<AttachedResource, "type">
  ) => {
    if (forUserNotes && resource.type !== AttachedResourceType.Link) {
      const pResource = {
        ...resource,
        attached_to_type: AttachedToType.USER_NOTES,
      } as Partial<AttachedResource> &
        Pick<
          AttachedResource,
          "type" | "title" | "description" | "attached_to_type"
        >;
      handleUpsertResourceInfoDialogSave(pResource);
    } else {
      dispatch(
        showUpsertResourceDialog({
          resource: {
            ...resource,
            attached_to_type: AttachedToType.USER_NOTES,
          },
          onSave: handleUpsertResourceInfoDialogSave,
          onClose: handleUpsertResourceInfoDialogHide,
        })
      );
    }
  };
  const handleUpsertResourceInfoDialogHide = (
    event: React.SyntheticEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    dispatch(hideUpsertResourceDialog());
  };
  const handleUpsertResourceInfoDialogSave = (
    pResource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => {
    let resource: AttachedResource;
    if (pResource.id) {
      setResources(
        resources1.map((res) =>
          res.id == pResource.id ? { ...res, ...pResource } : res
        )
      );
    } else {
      if (pResource.type === AttachedResourceType.Link) {
        resource = {
          ...pResource,
          id: -1 * +_.uniqueId(),
          created_at: "",
          updated_at: "",
        };
      } else {
        resource = {
          ...pResource,
          id: -1 * +_.uniqueId(),
          created_at: "",
          updated_at: "",
        };
      }

      setResources([...note.resources, resource]);
    }
    dispatch(hideUpsertResourceDialog());
  };
  const handleResourceRemove = (deletedResource: AttachedResource) => () => {
   
    setDeleteResourcesArray([...deleteResourcesArray,deletedResource])
    setResources(resources1.filter((r) => r.id !== deletedResource?.id));
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const titleValue = event.target.value;
    if (titleValue.length > 50) {
      setErrors({
        noteTitle: intl.formatMessage({ id: "app.validate.character.less" }),
      });
    } else {
      setErrors({ noteTitle: "" });
    }

    setFormData({ ...formData, noteTitle: titleValue });
  };

  useEffect(() => {
    // Enable or disable the editor based on the note title
    setEditorEnabled(
      !!formData.noteTitle && !!formData.noteTitle.trim() && !errors.noteTitle
    );
  }, [formData.noteTitle, errors.noteTitle]);

  useEffect(() => {
    if (note.resources && note.resources.length > 0) {
      setResources([...note.resources]);
    }
  }, [note.resources]);

  const stripHtmlTags = (htmlString: string): string => {
    return htmlString?.replace(/<[^>]*>/g, "");
  };

  const {
    userNotesType,
    selectedNote: preselectedNote,
    selectedActivity,
    agenda,
    inviterRole,
    inviterTeamRole,
    meeting,
    checkExisting,
  } = useSelector((s) => s.notes.dialogs.notesDialog);

  const handleNoteSave = () => {
    const { selectedNote, formData, meetingId, activePlanId, overview, resources1, isCalendarNote, deleteResourcesArray, authenticatedUser, selectedNoteId } = noteRefs;
    const requestBody: AddNotesRequest = {
      ...(selectedNote.current as any),
      is_draft: false,
      meeting_id: meetingId.current ?? undefined,
      note_type: selectedValueRef.current,
      note_title: formData.current.noteTitle,
      updated_at: new Date(),
      is_calender_note: isCalendarNote.current ? true : false,
      plan_id: activePlanId.current,
      text: overview.current ?? selectedNote.current?.text,
      activity: undefined,
      resources: resources1.current,
      onSuccess: (notes) => {
        // onSuccess && onSuccess(notes);
        history.push(
          generatePath(AuthRoutes.YourPlan, {
            workspace: "published-notes",
          } as any)
        );
      },
    };

    if (selectedNoteId.current) {
      dispatch(
        updateNotes.request({
          body: { ...requestBody, user: authenticatedUser.current?.id } as any,
          id: selectedNoteId.current,
        })
      );
      resources1.current.map((res: AttachedResource) =>
        dispatch(
          updateResource.request({
            resource: res,
            planId: activePlanId.current!,
          })
        )
      );
  
      deleteResourcesArray.current?.map((deletedResource: AttachedResource) =>
        dispatch(
          deleteResource.request({
            resourceId: deletedResource?.id,
            planId: activePlanId.current!,
          })
        )
      );
    } else {
      dispatch(addNotes.request({...requestBody}));
    }
  };

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>,
    onSuccess?: Function
  ) => {
    e && e.preventDefault();
    handleNoteSave();

    dispatch(
      setNavigationWarning({
        show: false,
        navigateTo:null,
        haveUnsavedChanges:false
      })
    );
    // events?.note_created
  };

  const handleNotesTypeChange = (selectedValue: any) => {
    dispatch(updateDialogNote({ ...note, note_type: selectedValue }));
  };

  const handleTextChange = (value: string) => {
    setOverview(value);
    dispatch(updateDialogNote({ ...note, text: value }));
  };

  const resetFormData = () => {
    if (selectedNoteId == null || selectedNoteId == 0) {
      setFormData({
        noteTitle: "",
      });
      setResources([]);
      setOverview("");
      setSelectedValue("");
      handleNotesTypeChange("");
    } else {
      history.push(
        generatePath(AuthRoutes.YourPlan, {
          workspace: "published-notes",
        } as any)
      );
    }
  };

  useEffect(() => {
    if (!isCalendarNote) {
      setIsSideMenuClosed(true);
    } else {
      setIsSideMenuClosed(false);
    }
  }, [isCalendarNote]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER, //"mt1", // PUSHER_CLUSTER
      channelAuthorization: {
        endpoint: "/pusher/auth-presence",
        transport: "ajax",
        params: { editNote: true },
        headers: {},
      },
      userAuthentication: {
        endpoint: "/pusher/auth-presence",
        transport: "ajax",
        headers: {
          "X-CSRF-Token": "some_csrf_token",
        },
      },
    });

    let NOTES_PRESENCE_CHANNEL =
      "presence-plan-" + activePlanId + "-note-" + (selectedNote as any)?.id;
    const presenceChannel = pusher.subscribe(NOTES_PRESENCE_CHANNEL);
    presenceChannel.bind(
      "pusher:subscription_succeeded",
      function (data: { data: any; is_new: boolean }) {
        // setSubscribersData({ users: pusherChannelSubscribed.members.members })
      }
    );

    presenceChannel.bind("pusher:member_added", (member: any) => {
      // console.log(">>> CREATE EDIT NOTE:member_added", member);
      // setSubscribersData({ users: pusherChannelSubscribed.members.members })
    });

    presenceChannel.bind("pusher:member_removed", (member: any) => {
      // console.log("CREATE EDIT NOTE:member_removed", member);
      // setSubscribersData({ users: pusherChannelSubscribed.members.members })
    });
    return () => {
      presenceChannel.unbind(); // Unbind event listeners
      presenceChannel.unbind_all();
      pusher.disconnect(); // Disconnect Pusher when component unmounts
    };
  }, [selectedNote, activePlanId]);

  // const handleUserActivity = () => {
  //   setIsIdle(false);
  //   resetTimer();
  // };

  useEffect(() => {
    // Attach event listeners for user activity
    // window.addEventListener("mousemove", handleUserActivity);
    // window.addEventListener("keydown", handleUserActivity);
    // window.addEventListener("click", handleUserActivity);
    // window.addEventListener("scroll", handleUserActivity);

    // Start the timer when the component mounts
    // resetTimer();

    return () => {
      // if user try to close the browser
      window.addEventListener("beforeunload", function (e) {
        // Standard way to trigger the confirmation dialog
        var confirmationMessage = "Are you sure you want to leave?";

        // For most browsers
        e.preventDefault(); // Necessary for Chrome
        e.returnValue = confirmationMessage; // Standard

        // For Firefox
        return confirmationMessage;
      });

      // Clean up event listeners and timeout
      // window.removeEventListener("mousemove", handleUserActivity);
      // window.removeEventListener("keydown", handleUserActivity);
      // window.removeEventListener("click", handleUserActivity);
      // window.removeEventListener("scroll", handleUserActivity);
      // if (timeoutRef.current) {
      // clearTimeout(timeoutRef.current);
      // }
    };
  }, []);

  useEffect(() => {
    dispatch(
      setNavigationWarning({
        haveUnsavedChanges: !(
          !stripHtmlTags(overview) || !stripHtmlTags(overview).trim()
        ),
      })
    );
  }, [overview]);

  useEffect(()=>{
    
    return ()=>{
      setDeleteResourcesArray([])
    }
  },[])


  const handleIdle = () => {
    console.log("User is idle");
    handleNoteSave();
  };

  const isUserIdle = useIdleTimer({
    timeout: 600000, // 5 mins
    onIdle: handleIdle,
  });

  return (
    <>
      <div className="sciMid-topBar">
        {isCalendarNote && (
          <div
            className={`left-bar-toggle ${isSideMenuClosed ? "ml-5" : ""}`}
            onClick={() => setIsSideMenuClosed(!isSideMenuClosed)}
          >
            {" "}
            {isSideMenuClosed ? (
              <Icon icon="chevron-right" iconSize={16} />
            ) : (
              <Icon icon="chevron-left" iconSize={16} />
            )}
          </div>
        )}
        <div className="editorHeading">
          <h2>
            {intl.formatMessage({
              id: "app.note-sci-start-writing-create-edit-note",
            })}
          </h2>
        </div>
      </div>
      {!isCalendarNote && (
        <div className="createNote_editor--feilds">
          <div className="nj-row">
            <div className="nj-sm-4">
              <TextField
                label={
                  <span>
                    {intl.formatMessage({
                      id: "app.note-sci-start-writing-title-note",
                    })}
                    <span className="redAsterisk">*</span>
                  </span>
                }
                id="outlined-size-small"
                size="small"
                fullWidth
                name="noteTitle"
                value={formData.noteTitle}
                onChange={handleTitleChange}
                error={errors.noteTitle !== ""}
                helperText={errors.noteTitle}
              />
            </div>
            <div className="nj-sm-4">
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {intl.formatMessage({
                    id: "app.note-sci-start-writing-note-by-topic",
                  })}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Tag Note by Topic"
                  value={selectedValue}
                  className={classes.select}
                  onChange={(event) => {
                    const value = event.target.value;
                    setSelectedValue(value);
                    handleNotesTypeChange(value);
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    PaperProps: {
                      className: classes.paper,
                    },
                  }}
                >
                  {options.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      )}
      <div className="editorWrapper">
      <FormGroup 
          className={isEditorEnabled ? undefined : "flex-1"} 
          contentClassName={isEditorEnabled ? undefined : "flex-1"}
        >
          <div className={`jodit-wrapper ${isOverviewError ? "isErrorOverview" : ""} ${!isEditorEnabled ? "enableEditor" : ""}`}>
          {!isEditorEnabled && (
            <div
              className="readOnlyEditorMode"
            >
              <p>Must add title to start writing...</p>
            </div>
          )}
          <JoditEditor
              ref={editorRef}
              value={overview}
              config={editorConfig}
              onChange={isEditorEnabled ? handleTextChange : setOverview}
              onBlur={(newContent) => handleTextChange(newContent)}
            />
          </div>
        </FormGroup>
      </div>
      <div className="filesWrapper">
        <div className="filesWrapper-buttonsDiv">
          <ResourcesTab
            strategyResources={resources1}
            setStrategyResources={updateResources}
            attachedToType={AttachedToType.USER_NOTES}
            forUserNotes
            onHideTabClick={() => false}
          />
          <p>
            {intl.formatMessage(
              { id: "app.resources.add-resources.subheading" },
              { strong: (chunks) => <strong>{chunks}</strong> }
            )}
          </p>
        </div>
        {!!resources1?.length && (
          <div className="filesDiv">
            {resources1?.find((r) => r?.type == "upload") && (
              <>
                <div className="labelHeading mb-3">
                  <strong>
                    {intl.formatMessage({
                      id: "app.note-sci-start-writing-heading-file",
                    })}
                  </strong>
                </div>
                <ResourcesListNote
                  resources={resources1}
                  onResourceEdit={handleResourceEdit}
                  onResourceRemove={handleResourceRemove}
                />
              </>
            )}
            {resources1?.find((r) => r?.type == "link") && (
              <>
                <div className="labelHeading">
                  <strong>
                    {intl.formatMessage({
                      id: "app.title.url",
                    })}
                  </strong>
                </div>
                {resources1?.length ? (
                  <div className="enterd-urls greyHead-table">
                    <TableContainer
                      sx={{ overflowY: "scroll", minHeight: 100, height: 200 }}
                    >
                      <Table
                        size="small"
                        sx={{ minWidth: 650 }}
                        aria-label="url table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {intl.formatMessage({
                                id: "app.resources.link.resource-title",
                              })}
                            </TableCell>
                            <TableCell>
                              {intl.formatMessage({
                                id: "app.resources.link.resource-url",
                              })}
                            </TableCell>
                            <TableCell>
                              {intl.formatMessage({
                                id: "app.titles.invite.action",
                              })}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {resources1.length
                            ? resources1.map(
                                (resource: AttachedResource, index: number) => {
                                  return resource?.type == "link" ? (
                                    <TableRow>
                                      <TableCell>{resource?.title}</TableCell>
                                      <TableCell>
                                        <a href={resource?.url} target="_blank">
                                          {resource?.url}
                                        </a>
                                      </TableCell>
                                      <TableCell>
                                        <button
                                          aria-label="Edit"
                                          onClick={() =>
                                            handleUpsertResourceDialogShow(
                                              resource
                                            )
                                          }
                                          className="editBtn"
                                        >
                                          <Icon icon="edit" iconSize={16} />
                                        </button>
                                        <button
                                          aria-label="Delete"
                                          onClick={() =>
                                            handleResourceRemove(resource)()
                                          }
                                          className="deleteBtn"
                                        >
                                          <Icon icon="trash" iconSize={16} />
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    ""
                                  );
                                }
                              )
                            : ""}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div className="createNote_editor--actionButton">
        <ScinoteSaveDialoge
          isDisabled={
            !stripHtmlTags(overview) || !stripHtmlTags(overview).trim()
          }
          onCancel={resetFormData}
        />

        <button
          className="solid-btn"
          ref={submitButtonRef}
          onClick={(e) => handleFormSubmit(e)}
          style={{ marginLeft: "15px" }}
          disabled={!stripHtmlTags(overview) || !stripHtmlTags(overview).trim() || !formData.noteTitle}
        >
          {intl.formatMessage({
            id: "app.note-sci-start-writing-save-close",
          })}{" "}
        </button>
      </div>
    </>
  );
};

export default memo(Noteseditor);
